import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Container, Row, Col, Form, Card, Button } from "react-bootstrap";
import logo from "../images/aie-logo.png";
import SendImg from "../images/Email Send.png";

function Chatbot() {
  const chatEndRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [isFinal, setIsFinal] = useState(false);
  const [finalResponse, setFinalResponse] = useState("");
  const [sessionId, setSessionId] = useState("");

  const generateSessionId = () => {
    return `session-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  };

  useEffect(() => {
    let existingSessionId = sessionStorage.getItem("sessionId");
    if (!existingSessionId) {
      const newSessionId = generateSessionId();
      sessionStorage.setItem("sessionId", newSessionId);
      setSessionId(newSessionId);
    } else {
      setSessionId(existingSessionId);
    }

    const savedMessages = sessionStorage.getItem("chatMessages");
    if (savedMessages) {
      setMessages(JSON.parse(savedMessages));
    }
  }, []);

  useEffect(() => {
    if (sessionId) {
      handleSendMessage("hi", sessionId);
    }
  }, [sessionId]);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = async (defaultInput = null, session = sessionId) => {
    const input = (defaultInput || userInput || "").toString();

    if (input.trim() === "") return;

    const updatedMessages = [...messages, { text: input, sender: "user" }];
    setMessages(updatedMessages);

    sessionStorage.setItem("chatMessages", JSON.stringify(updatedMessages));

    setIsTyping(true);

    const payload = {
      session_id: session,
      user_input: input,
    };

    setUserInput("");
    try {
      const response = await axios.post(
        "https://business-planner.brainvire.net/business_planner/",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const botMessage = {
        text: response.data.data.response,
        sender: "bot",
        img_encoding: response.data.img_encoding || null,
      };

      const newMessages = [...updatedMessages, botMessage];
      setMessages(newMessages);
      sessionStorage.setItem("chatMessages", JSON.stringify(newMessages));

      setIsFinal(response.data.is_final);
      if (response.data.is_final) {
        setFinalResponse(response.data.data.response);
      }
    } catch (error) {
      console.error("Error fetching data from API:", error);
    } finally {
      setIsTyping(false);
    }
  };

  const handleDownloadPDF = async () => {
    try {
      const imgEncodingsList = messages
        .filter((msg) => msg.img_encoding) 
        .map((msg) => msg.img_encoding);  
  
      const payload = {
        session_id: sessionId,
        user_input: finalResponse,
        img_encodings: imgEncodingsList,  
      };
  
      const response = await axios.post(
        "https://business-planner.brainvire.net/download_ppt/",
        payload,
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "BusinessPlan.pptx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the PPT:", error);
    }
  };
  
  

  const handleClearSession = () => {
    sessionStorage.clear();
    setMessages([]);
    setIsFinal(false);
    setFinalResponse("");
    const newSessionId = generateSessionId();
    sessionStorage.setItem("sessionId", newSessionId);
    setSessionId(newSessionId);
  };

  return (
    <>
      <nav className="navbar bg-white d-flex justify-content-center">
        <img href="/" className="navbar-brand" src={logo} alt="Logo" />
      </nav>
      <div
        className="main__app"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: messages.length === 0 ? "center" : "flex-end",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8} lg={8}>
              <Card style={{ border: "none" }}>
                <Card.Body
                  className={`${
                    messages.length === 0 ? "chat_card_noData" : "card_body"
                  }`}
                >
                  {messages.length !== 0 && !isTyping && (
                    <div className="chat-header d-flex justify-content-between">
                      <span>Create a Business Plan</span>
                      <span>
                        <i
                          className="fa-solid fa-rotate-right"
                          onClick={handleClearSession}
                          style={{ cursor: "pointer" }}
                        ></i>
                      </span>
                    </div>
                  )}
                  <div
                    className={`${
                      messages.length === 0 ? "chat_card_noData" : "chat_card"
                    }`}
                  >
                    {messages.map((msg, index) => (
                      <div
                        key={index}
                        className={`${
                          msg.sender === "user"
                            ? " d-flex mb-3 justify-content-end"
                            : "justify-content-start"
                        }`}
                      >
                        {msg.sender === "bot" && (
                          <div className="d-flex justify-content-start">
                            <p className="font-bold AI_Bot">AI Bot</p>
                          </div>
                        )}
                        {!(index === 0 && msg.sender === "user" && msg.text.toLowerCase() === "hi") && (
                          <div
                            className={`${
                              msg.sender === "user"
                                ? "user_text text-white mt-3"
                                : "user_replay"
                            }`}
                            style={{ maxWidth: "70%" }}
                            dangerouslySetInnerHTML={{
                              __html: msg.text.replace(/\n/g, "<br />"),
                            }}
                          />
                        )}
                        {msg.img_encoding && (
                          <div className="img_sec">
                            <img
                              src={`data:image/png;base64, ${msg.img_encoding}`}
                              alt="Generated visual"
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                          </div>
                        )}
                      </div>
                    ))}

                    {isTyping && (
                      <div className="d-flex mb-3 justify-content-start text-align-justify">
                        <div className="user_replay" style={{ maxWidth: "70%" }}>
                          <div className="typing-dots">
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </div>
                      </div>
                    )}
                    <div ref={chatEndRef} />
                    {isFinal && (
                      <Button
                        className="mt-3"
                        variant="primary"
                        onClick={handleDownloadPDF}
                        style={{
                          backgroundColor: "#244BC5",
                        }}
                      >
                        Download Plan
                      </Button>
                    )}
                  </div>
                  <div className="form-container">
                    {messages.length === 0 && !isTyping && <h6>What can I help with?</h6>}
                    <Form className="d-flex mt-3">
                      <Form.Control
                        type="text"
                        className="textArea"
                        placeholder="Type your message here..."
                        value={userInput}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                      />
                      <img
                        src={SendImg}
                        alt="Send Message"
                        className="send_img1"
                        onClick={handleSendMessage}
                        style={{
                          backgroundColor:
                            userInput.trim() === ""
                              ? "#728de0"
                              : "#244BC5",
                          cursor: userInput.trim() === "" ? "not-allowed" : "pointer",
                        }}
                      />
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <footer className="footer">
        <p className="py-3">
          <small> © 2025 Brainvire Infotech. All rights reserved.</small>
        </p>
      </footer>
    </>
  );
}

export default Chatbot;
